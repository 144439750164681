html,
body {
    height: 100%;
}

body {
    font-family: $font-family;
    line-height: 1.5;
}

.layout-sign {
    .sm-mobile {
        display: none;
    }
    .head-logo {
        padding: 2.5rem .5rem;
        background-color: $key-color;
        text-align: center;

        img {
            max-width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .title-head {
        color: $key-color;
        font-size: 3rem;
        font-weight: bold;
        margin-top: 3.5rem !important;
        margin-bottom: 2.5rem !important;
        text-align: center;
        padding-left: .938rem;
        padding-right: .938rem;
    }
    .content {
        max-width: 75%;
        margin: 0 auto;
    }
    form {
        label {
            word-break: break-all;
            word-break: break-word;
        }
    }
}

.layout-detail {
    .sm-mobile {
        display: none;
    }
    .navbar {
        background-color: $key-color;
    }
    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;
        img {
            max-width: 445px;
            max-height: 49px;
            @media screen and (max-width: 1080px) {
                max-width: 300px;
            }
            @media screen and (max-width: 890px) {
                max-width: 250px;
            }
        }
    }
}

@media screen and (min-width: 1636px) {
    .layout-sign {
        .content {
            max-width: 1140px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .layout-sign {
        .content {
            max-width: 85%;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .layout-detail {
        .navbar-brand {
            img {
                max-width: 250px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .layout-sign,
    .layout-detail {
        .sm-mobile {
            display: block;
        }
        .pc-desktop {
            display: none;
        }
    }
    .layout-sign {
        .head-logo {
            text-align: center;
            padding: 1.563rem .5rem;
            img {
                display: none;
                width: auto;
                max-width: 100%;
                max-height: 2.938rem;
                &.sm-mobile {
                    display: inline-block;
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .layout-sign {
        .head-logo {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        
        .content {
            max-width: 80%;
        }
    }
}

@media screen and (max-width: 480px) {
    .layout-sign {
        .title-head {
            font-size: 1.2em;
            margin-top: 2rem !important;
            margin-bottom: 2rem !important;
        }
        .content {
            max-width: 100%;
        }
    }
}