.set-fc-style{
    .set-fc-booth{
        &-name-card{
            .aside-staff {
                position: fixed;
                width: 115px;
                right: 20px;
                top: 8rem;
                @media only screen and (max-width: 768px) {
                    right: 0;
                    top: 7.5rem;
                    position: static;
                    width: 100%;
                    background: var(--m0d1r-key-color);
                    padding: 0.625rem;
                }
                ul {
                    margin: 0;
                    padding: 0;
                    border: 0;
                    font-size: 100%;
                    font: inherit;
                    vertical-align: bottom;
                    list-style: none;
                    @media only screen and (max-width: 768px) {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                    }
                    li {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        font-size: 100%;
                        font: inherit;
                        vertical-align: bottom;

                        @media only screen and (max-width: 768px) {
                            padding: 5px;
                            flex-basis: 33%;
                        }
                    }
                    a{
                        .set-fc-ofi-img{
                            background: #fff;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          img{
                            transform: scale(1);
                            transition: .3s ease-in-out;
                          }
                        }
                        &:hover{
                          .set-fc-ofi-img{
                            img{
                              transform: scale(1.2);
                            }
                          }
                          .aside-staff__item-info{
                            color: #666;
                          }
                        }
                    }
                }
                &__item {
                    padding: 1rem 2rem 0rem 3rem;
                    a{
                        margin: 0;
                        padding: 0;
                        border: 0;
                        font-size: 100%;
                        font: inherit;
                        vertical-align: bottom;
                        color: inherit;
                        outline: none;
                        border: none;
                        text-decoration: none;
                    }
                    &-img {
                        width: 100%;
                        height: 100%;
                        border-radius: 0;
                        border: 0;
                        background: #E0E0E0;
                        img {
                            width: 100%;
                            height: 100%;
                            -o-object-fit: cover;
                            object-fit: cover;
                            font-family: "object-fit: cover;";
                        }
                    }
                    &-info {
                        background: none;
                        font-size: 11px;
                        background-color: rgba(255, 255, 255, 0.8);
                        margin-top: 0;
                        padding: 5px;
                        position: relative;
                        color: #666;
                    }
                    &-img-card {
                        width: 55px;
                        position: absolute;
                        top: -30px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        @media only screen and (max-width: 480px) {
                            width: 40px;
                            top: -20px;
                        }
                    }
                }
            }
            &-modal{
                .is-visible.modal {
                    display: block;
                }
                .modal {
                    // min-width: 115rem;
                    width: 100%;
                    height: 100%;
                    background: rgba(51, 51, 51, 0.5);
                    position: fixed;
                    left: 0;
                    top: 0;
                    z-index: 1001;
                    display: none;
                    overflow: hidden;
                    outline: 0;
                    &__inner {
                        width: 100%;
                        height: 100%;
                        padding: 1rem;
                    }
                    &__contents {
                        width: 780px;
                        margin: auto;
                        background: #fff;
                        position: relative;
                    }
                    &__close, .second-modal .btn-close {
                        width: 5.4rem;
                        height: 5.4rem;
                        background: #333333;
                        transform: translate(50%, -50%);
                        border-radius: 50%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        cursor: pointer;
                        &::before {
                            content: "";
                            width: 3rem;
                            height: 0.3rem;
                            background: #fff;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                        }
                        &::after {
                            content: "";
                            width: 3rem;
                            height: 0.3rem;
                            background: #fff;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%) rotate(45deg);
                        }
                    }
                    &__contents-inner {
                        width: 100%;
                        max-height: 93vh;
                        overflow-x: hidden;
                        overflow-y: auto;
                        padding: 3rem 4rem 4rem;
                    }
                    &__block{
                        &-title {
                            color: var(--m0d1r-key-color);
                            font-size: 28px;
                            font-weight: 500;
                            letter-spacing: 0.1em;
                            text-align: center;
                            margin-bottom: 4rem;
                        }
                        &-desc{
                            font-size: 16px;
                            .font-sm{
                                font-size: 14px;
                            }
                        }
                    }
                    &__block-desc, .modal-business-card-auto .modal__block-desc {
                        max-width: 68rem;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    &__block-desc .font-sm, .modal-business-card-auto .modal__block-desc .font-sm {
                        display: block;
                        line-height: 1.8;
                        text-align: left;
                        margin-top: 1rem;
                    }
                    .frame_detail, .modal-business-card-auto .frame_detail {
                        text-align: center;
                    }
                    .frame_detail {
                        text-align: center;
                    
                        .radio-accept {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        }
                    
                        .frame-content {
                          border: 3px solid #333333;
                          padding: 3em;
                          margin-top: 3.1em;
                          margin-bottom: 2.8em;
                          margin-left: auto;
                          margin-right: auto;
                        }
                    
                        .info-member {
                          display: flex;
                          margin-left: auto;
                          margin-right: auto;
                        }
                    
                        .mem-avatar {
                            height: 100px;
                            width: 100px;
                            display: flex;
                            flex-grow: 0;
                            flex-shrink: 0;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border: 2px solid $key-color;
                            border-radius: 100%;
                            font-size: 18px;
                            p{
                                margin: 0;
                            }
                    
                          img {
                            max-width: 100%;
                          }
                        }
                    
                        .mem-profile {
                          padding-left: 2em;
                          text-align: left;
                    
                          p {
                            margin-bottom: .7em;
                            word-break: break-all;
                            word-break: break-word;
                    
                            br {
                              display: none;
                            }
                          }
                        }
                    
                        .actions {
                          margin-top: 1.5em;
                          display: flex;
                          flex-wrap: wrap;
                          justify-content: center;
                    
                          .btn-action {
                            font-size: 1em;
                            font-weight: normal;
                            text-align: center;
                            min-width: 12em;
                            color: $white;
                            background-color: #909090;
                            line-height: 3.5;
                            border: 0;
                            border-radius: 0;
                            margin-left: 1em;
                            margin-right: 1em;
                            cursor: pointer;
                    
                            &.btn-exchange {
                              background-color: $key-color;
                            }
                    
                            &:disabled, &.disabled {
                              background-color: #E3E3E3;
                            }
                          }
                        }
                    
                        .notice {
                          margin-top: 1em;
                          text-align: center;
                        }
                      }
                }
            }
        }
        &-menu{
            .menu {
                .mn-navigate{
                    .mn-item{
                        .icon {
                            img {
                                height: 30px;
                                max-width: 10rem;
                                margin: unset;
                            }
                        }
                        .mn-label{
                            font-size: 12px;
                        }
                    }
                } 
            }
            
        }
    }

    .set-fc-register{
        transition: all .3s;
        top: 0;
        position: relative;
        &:hover{
            background-color: #fff;
            color: var(--m0d1r-key-color);
            border: 1px solid var(--m0d1r-key-color);
            top:-3px;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
            opacity: 1;
        }
    }
    .set-fc-login{
        transition: all .3s;
        top: 0;
        position: relative;
        &:hover{
            background-color: #fff;
            color: var(--m0d1r-accent-color);
            border: 1px solid var(--m0d1r-accent-color);
            top:-3px;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
            opacity: 1;
        }
    }

    @-webkit-keyframes loopLeft {
        0% {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
        to {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }
    }
    @keyframes loopLeft {
        0% {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
        to {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }
    }
    @-webkit-keyframes loopRight {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
        to {
            -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
        }
    }
    @keyframes loopRight {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
        to {
            -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
        }
    }
    @-webkit-keyframes fluidrotate {
        0%,
            100% {
                border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
            }
            14% {
                border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
            }
            28% {
                border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
            }
            42% {
                border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
            }
            56% {
                border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
            }
            70% {
                border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
            }
            84% {
                border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
            }
        }
      @keyframes fluidrotate {
        0%,
        100% {
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
        }
        14% {
          border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
        }
        28% {
          border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
        }
        42% {
          border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
        }
        56% {
          border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
        }
        70% {
          border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
        }
        84% {
          border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
        }
      }
      @-webkit-keyframes fluidrotate01 {
        0%,
        100% {
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
        }
        14% {
          border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
        }
        28% {
          border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
        }
        42% {
          border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
        }
        56% {
          border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
        }
        70% {
          border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
        }
        84% {
          border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
        }
      }
      @keyframes fluidrotate01 {
        0%,
        100% {
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
        }
        14% {
          border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
        }
        28% {
          border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
        }
        42% {
          border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
        }
        56% {
          border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
        }
        70% {
          border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
        }
        84% {
          border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
        }
      }
      @-webkit-keyframes fluidrotate02 {
        0%,
        100% {
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
        }
        14% {
          border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
        }
        28% {
          border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
        }
        42% {
          border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
        }
        56% {
          border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
        }
        70% {
          border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
        }
        84% {
          border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
        }
      }
      @keyframes fluidrotate02 {
        0%,
        100% {
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
        }
        14% {
          border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
        }
        28% {
          border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
        }
        42% {
          border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
        }
        56% {
          border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
        }
        70% {
          border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
        }
        84% {
          border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
        }
      }
      @-webkit-keyframes fluidrotate03 {
        0%,
        100% {
          border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
        }
        14% {
          border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
        }
        28% {
          border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
        }
        42% {
          border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
        }
        56% {
          border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
        }
        70% {
          border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
        }
        84% {
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
        }
      }
      @keyframes fluidrotate03 {
        0%,
        100% {
          border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
        }
        14% {
          border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
        }
        28% {
          border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
        }
        42% {
          border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
        }
        56% {
          border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
        }
        70% {
          border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
        }
        84% {
          border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
        }
      }
      .fade {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
        opacity: 0;
        -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .fade.active {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      .fadeUp {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
        opacity: 0;
        -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .fadeUp.active {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      .fadeLeft {
        opacity: 0;
        -webkit-transform: translateX(80px);
        transform: translateX(80px);
      }
      .fadeLeft.active {
        -webkit-animation: ef-fadeleft 2.5s cubic-bezier(0.645, 0.045, 0.355, 1)
          forwards;
        animation: ef-fadeleft 2.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
      }
      .fadeRight {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px);
      }
        .fadeRight.active {
            -webkit-animation: ef-faderight 2.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
            animation: ef-faderight 2.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
        }
    
        .delay-fluid01{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
        }
        .delay-fluid02{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 0.4s;
            transition-delay: 0.4s;
        }

        .delay-fluid03{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 0.6s;
            transition-delay: 0.6s;
        }

        .delay-fluid04{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 0.8s;
            transition-delay: 0.8s;
        }
        .delay-fluid05{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 1s;
            transition-delay: 1s;
        }
        .delay-fluid06{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 1.2s;
            transition-delay: 1.2s;
        }

        .delay-fluid07{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 1.4s;
            transition-delay: 1.4s;
        }

        .delay-fluid08{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 1.6s;
            transition-delay: 1.6s;
        }
        .delay-fluid09{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 1.8s;
            transition-delay: 1.8s;
        }
        .delay-fluid010{
            -webkit-animation: fluidrotate01 15s ease 0s infinite;
            animation: fluidrotate01 15s ease 0s infinite;
            -webkit-transition-delay: 2s;
            transition-delay: 2s;
        }
      @-webkit-keyframes ef-fadeleft {
        0% {
          opacity: 0;
          -webkit-transform: translateX(80px);
          transform: translateX(80px);
        }
        100% {
          opacity: 1;
          -webkit-transform: translateX(0%);
          transform: translateX(0%);
        }
      }
      @keyframes ef-fadeleft {
        0% {
          opacity: 0;
          -webkit-transform: translateX(80px);
          transform: translateX(80px);
        }
        100% {
          opacity: 1;
          -webkit-transform: translateX(0%);
          transform: translateX(0%);
        }
      }
      @-webkit-keyframes ef-faderight {
        0% {
          opacity: 0;
          -webkit-transform: translateX(-80px);
          transform: translateX(-80px);
        }
        100% {
          opacity: 1;
          -webkit-transform: translateX(0%);
          transform: translateX(0%);
        }
      }
      @keyframes ef-faderight {
        0% {
          opacity: 0;
          -webkit-transform: translateX(-80px);
          transform: translateX(-80px);
        }
        100% {
          opacity: 1;
          -webkit-transform: translateX(0%);
          transform: translateX(0%);
        }
    }
}
.md-detail-member{
    &.set-fc-modal-card{
        .business{
            .mem-avatar{
                &.set-fc-mem-avatar{
                    width: 100px;
                    height: 100px;
                }
            }
            .mem-profile {
                p{
                    font-size: 12px;
                    @media only screen and (max-width: 480px) {
                        font-size: 12px !important;
                    }
                }
            }
            .notice{
                font-size: 12px;
                @media only screen and (max-width: 480px) {
                    font-size: 12px !important;
                }
            }
            .actions {
                .btn-action{
                    &.btn-exchange {
                        background-color: #C64242;
                    }
                    &.disabled {
                        background-color: #E3E3E3;
                    }
                    
                }
            }
        }
        .set-fc-modal-card-btn-accept{
            background: #fff;
            border: 1px solid #707070;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 12px;
            color: #333333;
            @media only screen and (max-width: 480px) {
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
        .set-fc-modal-card-btn-disagree{
            background: #D9D9D9;
            border: 1px solid #D9D9D9;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 12px;
            color: #333333;
            @media only screen and (max-width: 480px) {
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
        .set-fc-accept-exchange-bg{
            background: #D9D9D9;
        }
        .modal__block-title{
            color: #C64242;
            position: relative;
            margin-bottom: 0;
            background: url("../images/layout/background-top-title.png") no-repeat center center;
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: top;
            padding: 25px;
            border-radius: 20px 20px 0 0;
            &-detail{
                font-size: 18px;
                color: #333333;
                text-align: center;
                padding: 30px;
                @media only screen and (max-width: 480px) {
                    padding: 10px;
                }
        
            }
            &::before{
                content: "";
                position: absolute;
                width: 50px;
                top: 0;
                bottom: 0;
                left: 10%;
                background: url("../images/layout/background-top-title-right.png") no-repeat center center;
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-position: top;
                height: 30px;
                margin-top: auto;
                margin-bottom: auto;
                @media only screen and (max-width: 480px) {
                    left: 0;
                }
            }
            &::after{
                content: "";
                position: absolute;
                width: 50px;
                top: 0;
                bottom: 0;
                right: 10%;
                background: url(/images/background-top-title-left.png?2fff480…) no-repeat center center;
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-position: top;
                height: 30px;
                margin-top: auto;
                margin-bottom: auto;
                @media only screen and (max-width: 480px) {
                    right: 0;
                }
            }
            
        }
    }
   
}
.set-footer-term-pri{
    position: absolute;
    bottom: 0;
    left: 15%;
    a{
        color: $white;
        text-decoration: underline;
        font-size: 12px;
    }
   
}

.set-fc-click-not-allowed{
    cursor: not-allowed;
}

.set-btn-accent-color{
    background-color: $accent-color !important;
    border-color: $accent-color !important;
}

a{
  &:hover{
    opacity: .7;
    text-decoration: none !important;
  }
}

.set-fc-pointer{
    cursor: pointer;
    &:hover{
        cursor: pointer;
    }
}

.a-underline{
    text-decoration: underline !important;
    &:hover{
        text-decoration: none !important;
        opacity: .7;
    }
}

.set-fc-board-projector-item{
  .set-fc-hover-style{
    &:hover{
      opacity: 1 !important;
    }
  }
}

.swal2-close{
  &.btn-close{
    &:hover{
      opacity: .7 !important;
    }
  }
}

.set-fc-df-style{
  &.set-hv-full{
    height: 100vh;
  }
  &.set-hv-helf{
    height: 50vh;
  }
}

.set-fc-register-input{
  display: inline-block;
  width: 90%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.set-fc-register-input-short{
  display: inline-block;
  min-width: 20rem;
  width: auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
.badge{
  &.set-font-size-box{
    font-size: 1.125rem;
  }
}

.news-wrapper {
  .is-new {
    color: #F48686;
    width: 50px;
    font-weight: bold;
  }
}