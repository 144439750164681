.top-navbar {
	&.fixed {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100;
	}

	.top-menu {
		background-color: $white;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 100;
		transition: all .5s ease-in-out;
		height: 0;
		.list-menu {
			list-style: none;
			margin: 0;
			padding: 0;
			color: $white;
			height: 100%;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-direction: column;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			text-align: center;
			-ms-overflow-y: auto;
			overflow-y: auto;
			padding-top: 100px;
			margin-top: 5rem;
			padding-bottom: 10rem;
			li {
				display: -webkit-inline-flex;
				display: inline-flex;
				padding: .7rem .4rem;
			}

			a {
				color: $key-color;
				text-decoration: none;

				display: inline-block;
				vertical-align: middle;
				-webkit-transform: perspective(1px) translateZ(0);
				transform: perspective(1px) translateZ(0);
				box-shadow: 0 0 1px rgb(0 0 0 / 0%);
				position: relative;
				overflow: hidden;
				padding: 0 0 2px;

				&:before {
					content: "";
					position: absolute;
					z-index: -1;
					left: 0;
					right: 100%;
					bottom: 0;
					background: $key-color;
					height: 1px;
					-webkit-transition-property: right;
					transition-property: right;
					-webkit-transition-duration: 0.4s;
					transition-duration: 0.4s;
					-webkit-transition-timing-function: ease-out;
					transition-timing-function: ease-out;
				}
				&:hover{
					&:before {
						right: 0;
					}
				}
			}

			.btn-logout {
				border-radius: 100px;
				background-color: $white;
				font-weight: normal;
				margin-top: .2rem;
				min-width: 200px;
				font-size: 1em;
				display: none;
			}

			.top-lang {
				position: initial;
			}
		}
		&.show{
			height: 100%;
			overflow: hidden;
		}
	}

	.navbar {
		height: 4.1rem;
		position: relative;
		z-index: 1000;
		background-color: $key-color;
		padding: 0rem 2%;
	}

	.navbar-toggler {
		border: 0;

		&:focus-visible {
			outline: none;
		}

		.navbar-toggler-icon {
			background: transparent;
			position: relative;
			display: inline-block;
			cursor: pointer;
			width: 1.8em;
			height: 1.2em;

			>span {
				position: absolute;
				height: 3px;
				background: $white;
				width: 100%;
				top: 4%;
				left: 0;
				-webkit-transition: .5s;
				-moz-transition: .5s;
				-ms-transition: .5s;
				-o-transition: .5s;
				transition: .5s;

				&:nth-child(2) {
					top: 42%;
				}

				&:nth-child(3) {
					top: 80%;
					bottom: 0;
				}
			}
			&:hover {
				> span{
					&:first-of-type {
						-webkit-transform: translateY(-100%);
	        			transform: translateY(-100%);
					}

					&:last-of-type {
						-webkit-transform: translateY(100%);
	        			transform: translateY(100%);
					}
				}
			}
		}

		&[aria-expanded=true] {
			.navbar-toggler-icon {
				-webkit-transition: .3s;
				-moz-transition: .3s;
				-ms-transition: .3s;
				-o-transition: .3s;
				transition: .3s;
				>span {
					

					&:nth-child(1) {
						-webkit-transform: translateY(8px) rotate(-45deg);
						-moz-transform: translateY(8px) rotate(-45deg);
						-ms-transform: translateY(8px) rotate(-45deg);
						-o-transform: translateY(8px) rotate(-45deg);
						transform: translateY(8px) rotate(-45deg);
					}

					&:nth-child(3) {
						-webkit-transform: translateY(-11px) rotate(45deg);
						-moz-transform: translateY(-11px) rotate(45deg);
						-ms-transform: translateY(-11px) rotate(45deg);
						-o-transform: translateY(-11px) rotate(45deg);
						transform: translateY(-11px) rotate(45deg);
					}

					&:nth-child(2) {
						opacity: 0;
						visibility: hidden;
						backface-visibility: hidden;
					}
				}
			}
		}
	}

	.navbar-brand {
		.sm-mobile {
			display: none;
		}
	}

	.top-notify {
		font-size: .8em;
		padding-left: .5em;
	}

	.group-button-top {
		.btn-mypage {
			height: 49px;
			width: 179px;
			margin-right: 0.4rem;
			text-align: center;
			line-height: 2.4rem;
			border-radius: .5rem;

			&.set-fc-btn-mypage {
				font-size: 14px;
				font-weight: bold;
				background: none;
				border: none;
				border-right: 1px solid #ffffff;
				border-radius: 0;
				height: 30px;
				line-height: inherit;
				cursor: pointer;
                width: auto;
                padding-right: 20px;
                font-weight: 500;
				&:hover {
					opacity: 0.7;
                    text-decoration: underline;
				}
				@media screen and (max-width: 952px) {
					font-size: 11px;
					min-width: 80px;
				}
				@media screen and (max-width: 870px) {
					padding-right: 10px;
				}
			}
		}

		.btn-logout {
			height: 49px;
			width: 179px;
			margin-right: 1rem;
			text-align: center;
			line-height: 2.4rem;
			border-radius: .5rem;

			&.set-fc-btn-logout {
				font-size: 14px;
				font-weight: bold;
				background: none;
				border: none;
				height: 30px;
				line-height: inherit;
				cursor: pointer;
                width: auto;
                font-weight: 500;
				&:hover {
					opacity: 0.7;
				}
				@media screen and (max-width: 952px) {
					font-size: 11px;
					min-width: 80px;
				}
				@media screen and (max-width: 770px) {
					font-size: 11px;
					min-width: 75px;
				}
			}
		}
		
		.top-lang {
			&.set-fc-top-lang {
				background-color: transparent;

				.set-fc-lang {
					border-color: $white;
					color: #333333;
					background: $white;
					font-size: 14px;
					font-weight: 400;
					padding-left: 20px;
					padding-right: 20px;
					@media screen and (max-width: 768px) {
						top: 44.5rem !important;
					}
					@media screen and (max-width: 480px) {
						padding-left: 15px;
						padding-right: 15px;
					}
				}
			}
		}

		.btn-lang {
			padding: 0 5px;
			line-height: 1;
			border: 0;

			.top-lang {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;
				position: initial;
				right: 0;
				top: 0;
				min-width: 10px;
				line-height: 1;

				.nav-link.dropdown-toggle {
					line-height: 1;
				}
			}
		}
		.btn-lang-sp{
			display: none;
		}
	}
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
	.top-navbar {
		.navbar {
			padding: 0rem 1.5%;
		}

		.top-notify {
			font-size: .7em;
		}

		.group-button-top {

			.btn-logout,
			.btn-mypage {
				width: 155px;
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.top-navbar {
		.navbar-brand {
			position: relative;
		}

		.top-notify {
			font-size: .6em;
		}

		.group-button-top {

			.btn-logout,
			.btn-mypage {
				margin-right: 0;
				width: 110px;
			}
		}
	}
}
@media screen and (max-width: 1080px) {
	.top-navbar {
		.top-notify {
			font-size: .5em;
		}
	}
}


@media screen and (max-width: 815px) {
	.set-fc-btn-lang{
		width: 100px !important;
		min-width: 100px !important;
	}
	.navbar-toggler {
		padding: 0;
	}
}
@media screen and (max-width: 768px) {
	.top-navbar {
		.top-menu {
			.list-menu {
				.btn-logout {
					display: inline-block;
				}
			}
		}

		.navbar {
			padding-top: .5rem;
			padding-bottom: .5rem;

			.navbar-brand {
				width: 52%;
				margin-right: 0rem;

				img {
					display: none;

					&.sm-mobile {
						width: auto;
						max-width: 100%;
						max-height: 40px;
						display: inline-block;
					}
				}
			}
		}

		.top-notify {
			display: none;
		}

		.group-button-top {
			.btn-mypage {
				font-size: .85em;
				min-width: 75px;
				width: 145px;
				margin-right: .4rem;
                &.set-fc-btn-mypage{
                    border-right: 0;
                    width: auto;
					padding-right: 0;
                }
			}

			.btn-logout {
				display: none;
			}
			.btn-lang-sp{
				display: inline-block;
				width: 80px;
			}

			.navbar-toggler {
				padding-right: 0;
			}
            
		}
	}
}

@media screen and (max-width: 480px) {
	.top-navbar {
		.navbar {
			height: 4rem;
			padding: 12px;
			.navbar-brand {
				width: 30%;
			}
		}

		.navbar-brand {
			margin-right: 0;
			
			img {
				max-width: 260px;
			}
		}

		.group-button-top {
			display: flex;
			.btn-mypage {
				width: 95px;
				height: 40px;
				line-height: 30px;
				&.set-fc-btn-mypage{
					font-size: 10px;
					color: #E6E6E6;
					text-decoration: underline;
					margin-right: 0;
					opacity: .7;
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}
			}

			.navbar-toggler {
				padding-left: 0;
			}
			.btn-lang-sp{
				height: auto;
				.dropdown-menu{
					min-width: auto;
					padding: 2px;
				}
			}
		}
	}
}