#bnr {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    display: inline-block;
    top: 0;
    left: 0;
    font-size: 14px;
}

.bnr-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #fff;
    padding: 50px 20px;
    border-radius: 10px;
}

.bnr-box {
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 8px;
}

.bnr-box p {
    text-align: left;
    font-weight: 300;
}

#btn {
    background-color: #115caa;
    color: #fff;
    padding: 12px 30px;
    clear: both;
    display: inline-block;
    margin-top: 10px;
    border-radius: 100px;
    cursor: pointer;
    margin-right: 5px;
    font-size: 18px;
    border: none;
    transition: all 0.2s 0s ease;
    height: 50px;
    width: 130px;
}

#btn:hover {
    opacity: 0.5;
}

.nolink {
    background-color: #d2d2d2;
    color: #fff;
    padding: 10px 30px;
    clear: both;
    display: inline-block;
    margin-top: 10px;
    border-radius: 100px;
    font-size: 18px;
    height: 50px;
    width: 130px;
}

.privacy-link {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    #bnr {
        font-size: 14px;
    }
    .bnr-inner {
        width: 90%;
    }
    .bnr-box p {
        line-height: 1.5;
    }
}