@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.set-fc-ff-noto-sans{
    font-family: 'Noto Sans', 'Noto Sans JP',sans-serif;
}
.set-fc-fw-regular{
    font-weight: normal !important;
}
.set-fc-fw-medium{
    font-weight: lighter !important;
}
.set-fc-fw-bold{
    font-weight: bold !important;
}
@for $i from 1 through 9 {
    .set-fc-fw-#{100 * $i} { font-size: $i * 100 !important; }
}
@for $i from 1 through 100 {
    .set-fc-fs-#{$i} { font-size: 1px * $i !important; }
}