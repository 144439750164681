.set-fc-style-animation {
	.set-fc-register {
		&:hover {
			background-color: #333;
		}
	}

	.set-fc-login {
		&:hover {
			background-color: #333;
		}
	}

	@-webkit-keyframes loopLeft {
		0% {
			-webkit-transform: translateX(100%);
			transform: translateX(100%);
		}

		to {
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
		}
	}

	@keyframes loopLeft {
		0% {
			-webkit-transform: translateX(100%);
			transform: translateX(100%);
		}

		to {
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
		}
	}

	@-webkit-keyframes loopRight {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
		}

		to {
			-webkit-transform: translateX(-200%);
			transform: translateX(-200%);
		}
	}

	@keyframes loopRight {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
		}

		to {
			-webkit-transform: translateX(-200%);
			transform: translateX(-200%);
		}
	}

	@-webkit-keyframes fluidrotate {

		0%,
		100% {
			border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
		}

		14% {
			border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
		}

		28% {
			border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
		}

		42% {
			border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
		}

		56% {
			border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
		}

		70% {
			border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
		}

		84% {
			border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
		}
	}

	@keyframes fluidrotate {

		0%,
		100% {
			border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
		}

		14% {
			border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
		}

		28% {
			border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
		}

		42% {
			border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
		}

		56% {
			border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
		}

		70% {
			border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
		}

		84% {
			border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
		}
	}

	@-webkit-keyframes fluidrotate01 {

		0%,
		100% {
			border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
		}

		14% {
			border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
		}

		28% {
			border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
		}

		42% {
			border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
		}

		56% {
			border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
		}

		70% {
			border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
		}

		84% {
			border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
		}
	}

	@keyframes fluidrotate01 {

		0%,
		100% {
			border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
		}

		14% {
			border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
		}

		28% {
			border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
		}

		42% {
			border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
		}

		56% {
			border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
		}

		70% {
			border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
		}

		84% {
			border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
		}
	}

	@-webkit-keyframes fluidrotate02 {

		0%,
		100% {
			border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
		}

		14% {
			border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
		}

		28% {
			border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
		}

		42% {
			border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
		}

		56% {
			border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
		}

		70% {
			border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
		}

		84% {
			border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
		}
	}

	@keyframes fluidrotate02 {

		0%,
		100% {
			border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
		}

		14% {
			border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
		}

		28% {
			border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
		}

		42% {
			border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
		}

		56% {
			border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
		}

		70% {
			border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
		}

		84% {
			border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
		}
	}

	@-webkit-keyframes fluidrotate03 {

		0%,
		100% {
			border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
		}

		14% {
			border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
		}

		28% {
			border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
		}

		42% {
			border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
		}

		56% {
			border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
		}

		70% {
			border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
		}

		84% {
			border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
		}
	}

	@keyframes fluidrotate03 {

		0%,
		100% {
			border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
		}

		14% {
			border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
		}

		28% {
			border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
		}

		42% {
			border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
		}

		56% {
			border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
		}

		70% {
			border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
		}

		84% {
			border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
		}
	}

	.fade {
		-webkit-transform: translateY(30px);
		transform: translateY(30px);
		opacity: 0;
		-webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.fade.active {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	.fadeUp {
		-webkit-transform: translateY(30px);
		transform: translateY(30px);
		opacity: 0;
		-webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.fadeUp.active {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	.fadeLeft {
		opacity: 0;
		-webkit-transform: translateX(80px);
		transform: translateX(80px);
	}

	.fadeLeft.active {
		-webkit-animation: ef-fadeleft 2.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
		animation: ef-fadeleft 2.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
	}

	.fadeRight {
		opacity: 0;
		-webkit-transform: translateX(-80px);
		transform: translateX(-80px);
	}

	.fadeRight.active {
		-webkit-animation: ef-faderight 2.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
		animation: ef-faderight 2.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
	}

	.delay-fluid01 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 0.2s;
		transition-delay: 0.2s;
	}

	.delay-fluid02 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 0.4s;
		transition-delay: 0.4s;
	}

	.delay-fluid03 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 0.6s;
		transition-delay: 0.6s;
	}

	.delay-fluid04 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 0.8s;
		transition-delay: 0.8s;
	}

	.delay-fluid05 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 1s;
		transition-delay: 1s;
	}

	.delay-fluid06 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 1.2s;
		transition-delay: 1.2s;
	}

	.delay-fluid07 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 1.4s;
		transition-delay: 1.4s;
	}

	.delay-fluid08 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 1.6s;
		transition-delay: 1.6s;
	}

	.delay-fluid09 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 1.8s;
		transition-delay: 1.8s;
	}

	.delay-fluid010 {
		-webkit-animation: fluidrotate01 15s ease 0s infinite;
		animation: fluidrotate01 15s ease 0s infinite;
		-webkit-transition-delay: 2s;
		transition-delay: 2s;
	}

	@-webkit-keyframes ef-fadeleft {
		0% {
			opacity: 0;
			-webkit-transform: translateX(80px);
			transform: translateX(80px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0%);
			transform: translateX(0%);
		}
	}

	@keyframes ef-fadeleft {
		0% {
			opacity: 0;
			-webkit-transform: translateX(80px);
			transform: translateX(80px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0%);
			transform: translateX(0%);
		}
	}

	@-webkit-keyframes ef-faderight {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-80px);
			transform: translateX(-80px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0%);
			transform: translateX(0%);
		}
	}

	@keyframes ef-faderight {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-80px);
			transform: translateX(-80px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0%);
			transform: translateX(0%);
		}
	}

	.set-fc-animation {

		/*========= レイアウトのためのCSS ===============*/
		&.flex {
			display: flex;
			flex-wrap: wrap;
		}

		/*==================================================
		ふわっ
		===================================*/

		&.fadeUp {
			animation-name: fadeUpAnime;
			animation-duration: 1.5s;
			animation-fill-mode: forwards;
			opacity: 0;
		}

		&.fadeDown {
			animation-name: fadeDownAnime;
			animation-duration: 1.5s;
			animation-fill-mode: forwards;
			opacity: 0;
		}

		&.fadeLeft {
			animation-name: fadeLeftAnime;
			animation-duration: 1.5s;
			animation-fill-mode: forwards;
			opacity: 0;
		}

		&.fadeRight {
			animation-name: fadeRightAnime;
			animation-duration: 1.5s;
			animation-fill-mode: forwards;
			opacity: 0;
		}

		@keyframes fadeUpAnime {
			from {
				opacity: 0;
				transform: translateY(100px);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes fadeDownAnime {
			from {
				opacity: 0;
				transform: translateY(-100px);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes fadeLeftAnime {
			from {
				opacity: 0;
				transform: translateX(100px);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@keyframes fadeRightAnime {
			from {
				opacity: 0;
				transform: translateX(-100px);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		/* アニメーションスタートの遅延時間を決めるCSS*/

		&.delay-time02 {
			animation-delay: 0.2s;
		}

		&.delay-time03 {
			animation-delay: 0.3s;
		}

		&.delay-time04 {
			animation-delay: 0.4s;
		}

		&.delay-time05 {
			animation-delay: 0.5s;
		}

		&.delay-time06 {
			animation-delay: 0.6s;
		}

		&.delay-time07 {
			animation-delay: 0.7s;
		}

		&.delay-time08 {
			animation-delay: 0.8s;
		}

		&.delay-time09 {
			animation-delay: 0.9s;
		}

		&.delay-time10 {
			animation-delay: 1s;
		}
	}

	.modal__contents {
		transform: scale(0);
		transition: 0.5s;

		&.modal-animation {
			transform: scale(1);
			transition: 0.5s;
		}
	}

	.set-hover-op {
		display: block;
		height: 100%;
		transition: .3s;

		&:hover {
			opacity: 0.8;
			box-shadow: 0 15px 30px -5px rgba(0, 0, 0, .15), 0 0 5px rgba(0, 0, 0, .1);
			transform: translateY(-4px);
			scale: 1.05;
		}
	}


	.stag-list-animation {
		animation-name: fadeUpAnime;
		animation-duration: 1.5s;
		animation-fill-mode: forwards;
		opacity: 0;
		&.loading {
			display: none;
		}
	}
    .tab-animation-date-time{
        &.active{
            opacity: 0;
        }
        &.show{
            animation-name: fadeUpAnime;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
            opacity: 1;
        }
    }
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@-moz-keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@-webkit-keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@-o-keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@-ms-keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
}

.set-fc-pointer {
	cursor: pointer;

	&:hover {
		cursor: pointer;
	}
}

body:not(.is-menu--open) {
	.set-cus-menu-hum {
		transition: 0.5s;

		&:hover {
			i::before {
				transform: translateY(1.6rem);

				@media only screen and (max-width: 768px) {
					transform: translateY(0.5rem);
				}
			}

			i::after {
				transform: translateY(-1.6rem);

				@media only screen and (max-width: 768px) {
					transform: translateY(-0.5rem);
				}
			}
		}

	}
}


.a-underline {
	text-decoration: underline !important;

	&:hover {
		text-decoration: none !important;
		opacity: .7;
	}

	&-none {
		text-decoration: none !important;
	}
}

.set-fc-hover-style {
	&:hover {
		opacity: .7 !important;
	}
}

.popup-modal-reserve{
	.btn-close{
		&:hover{
			background: #333333 !important;
			opacity: 0.7 !important;
		}
	}
}