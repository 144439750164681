.swal2-container {
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
  overflow: auto !important;
  .sweet-modal {
    transform: scale(0.3);
    transition: 0.5s;
    &.swal2-show{
      transform: scale(1);
    }
  }
}

.sweet-modal {
  width: 100% !important;
  max-width: 1024px !important;
  padding: 0 !important;
  border-radius: 0 !important;

  .swal2-html-container {
    margin: 0;
    padding: 0;
    font-size: 1em !important;
  }

  &.swal2-show {
    opacity: 1;
    -webkit-animation: none;
    -o-animation: none;
    animation: none;
  }

  &.swal2-hide {
    opacity: 0;
  }
  

  .btn-close {
    position: absolute;
    background-color: rgba(0, 0, 0, .9);
    border-radius: 100%;
    top: -1.2rem;
    right: -1.2rem;
    outline: none;
    width: 1.3em;
    height: 1.3em;
    font-weight: normal;
    line-height: 1;
    text-indent: -9999px;
    color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, .9);
    }

    &:before {
      content: "";
      display: block;
      width: 1.5rem;
      height: 0.15rem;
      background: $white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      content: "";
      display: block;
      width: 1.5rem;
      height: 0.15rem;
      background: $white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .swal2-actions {
    margin: 1rem auto;
    display: flex;

    button {
      color: inherit;
      border: 1px solid;
      text-decoration: none;
      text-align: center;
      border-radius: 0;
      min-width: 200px;
      background-color: $white;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }
    }

    .btn-close2 {
      position: relative;
      padding-left: 30px !important;
      min-width: 150px;

      &:before {
        content: "";
        display: block;
        width: 1.3rem;
        height: 0.15rem;
        background: #333;
        position: absolute;
        left: auto;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }

      &:after {
        content: "";
        display: block;
        width: 1.3rem;
        height: 0.15rem;
        background: #333;
        position: absolute;
        left: auto;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    .btn-default {
      background-color: #E3E3E3;
      border-radius: 4px;
      border-color: #E3E3E3;
      padding-left: 1.3rem;
      padding-right: 1.3rem;
    }

    .btn-green {
      background-color: $green-alt;
      border-radius: 4px;
      color: $white;
      border-color: $green-alt;
      order: 2;
      padding-left: 1.3rem;
      padding-right: 1.3rem;

      &:hover {
        background-color: $green-alt;
      }
    }

    .btn-orange-light {
      background-color: $orange-light;
      border-radius: 4px;
      color: $white;
      border-color: $orange-light;
      order: 2;
      padding-left: 1.3rem;
      padding-right: 1.3rem;

      &:hover {
        background-color: $orange-light;
      }
    }
  }
}