.btn {
    font-size: $font-size-base;
}

.link-dexpo {
    color: $color-link;
    text-decoration: underline;

    &.text-green-alt {
        &:hover {
            color: $green-dark;
        }
    }
}

.content-wrapper {
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    -o-flex: 1 1 auto;
    -khtml-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-direction: column;

    > .container-fluid {
        display: flex;
        -webkit-flex: 1 1 auto;
        -moz-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        -o-flex: 1 1 auto;
        -khtml-flex: 1 1 auto;
        flex: 1 1 auto;
        flex-direction: column;
        overflow: hidden;
    }
}

.content-container {
    padding: 15px;
    background: #ffffff;
    color: $black-light;
    font-weight: bold;
    margin-bottom: 1rem;
}

.background-key-color {
    background-color: $key-color !important;
    &.reset-background-key-color {
        background-color: $key-bg-dark-title !important;
    }
}

.background-sub-color {
    background-color: $sub-color !important;
}

.background-accent-color {
    background-color: $accent-color !important;
}