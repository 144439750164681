@import "../variables";
@import "../components/special-stage-item";
.sm-mobile {
    display: none;
}

// Common section
.section-title {
    @include title-header;
    overflow: visible;
    font-size: 1.875rem;
    &::before {
        content: attr(data-overlay);
        @include title-behind;
        font-size: 10rem;
    }

    &.set-rt-vrunder{
        font-size: 46px;
        font-weight: bold;
        color: #333333;
        &::before {
            content: attr(data-overlay);
            @include title-border-under;
            font-size: 10rem;
        }
    }
}

.section-desc {
    p {
        margin: 0;
        line-height: 1.8;
    }
}
//Top lang
.top-lang {
    position: absolute;
    right: 5rem;
    top: 2rem;
    min-width: 10rem;
    z-index: 1;
    background-color: #fff;
    border-radius: 0.2rem;
    .dropdown-menu {
        min-width: 10rem;
    }
    .set-fc-lang{
        border: 1px solid #727272;
        border-radius: 2px;
        color: #727272;
        text-align: center;
        &.nav-link{
            padding: 0.3rem 0.5rem;
        }
    }
    &.set-fc-w{
        min-width: 8rem;
    }
}

// Top header
.top-header {
    background-color: $key-color;
    // padding-top: .875em;
    .top-slogan {
        overflow: hidden;
        max-height: 40rem;
        min-height: 30rem;
        position: relative;
        display: flex;
        img {
            display: inline-flex;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
            width: 100%;
            &.sm-mobile {
                display: none;
            }
        }
        .slogan {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            display: -ms-flex;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            -webkit-flex: 1 1 auto;
            -moz-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            -o-flex: 1 1 auto;
            -khtml-flex: 1 1 auto;
            flex: 1 1 auto;
        }
        .slogan-text {
            padding: 0;
            margin: 5px 0;
            font-weight: bold;
            font-size: 2rem;
            line-height: 1.3;
            color: $black-light;
            &:nth-child(2) {
                color: $red-alt;
            }
        }
        &-setrt{
            max-height: unset;
            min-height: unset;
            .bg-color{
                background: #FFFFFF
            }
            .setrt-head-logo{
                &-text{
                    &.line{
                        display:block; 
                        margin: 25px;
                        width: 100%;
                        h4{
                            font-size:15px; 
                            text-align:center; 
                            border-bottom:1px solid #305767; 
                            position:relative;
                            span{
                                background-color: white; 
                                position: relative; 
                                top: 10px; 
                                padding: 0 10px;
                                font-size: 18px;
                                color: #305767;
                                font-weight: bold;
                            }
                        }
                    }
                }

                &-date{
                    h4{
                        color: #333333;
                        font-weight: bold;
                        font-size: 1.5vw;
                    }
                    &-time{
                        color: #333333;
                        font-weight: bold;
                        font-size: 18px;
                        text-align: center;
                    }
                }

                &-sns{
                    display: flex;
                    a{
                        display: flex;
                        width: 55px;
                        img{
                            display: flex;
                            width: 55px;
                            padding: 10px;
                        }
                    }
                    
                }
            }
            .carousel .carousel-item > div {
                width: 100%;
                height: 100vh;
                background-size: cover;
                background-position: center;
            }
            
            .carousel-control-prev-icon, .carousel-control-next-icon {
                width: 50px;
                height: 50px;
            }
        }
    }

    .top-intro {
        background-color: $key-color;
        // position: relative;
        // max-height: 0;
        .content-intro {
            min-height: 10rem;
            position: relative;
            max-width: 96%;
            margin-left: auto;
            margin-right: auto;
            // top: 110px; RT
            &.no-date-release {
                min-height: 3rem;
            }
        }
        .box-intro {
            // background-color: $ice-landscape;
            // position: absolute;
            // left: 0;
            // right: 0;
            // bottom: 0;
            text-align: center;
            z-index: 2;
        }
        .intro {
            img {
                max-width: 100%;
                &.sm-mobile {
                    display: none;
                }
            }
            .note-held {
                margin-top: .75rem;
                margin-bottom: 0;
                font-size: .6rem;
            }
        }
        .intro-button {
            // background-color: $white; RT
            list-style: none;
            padding: 1.5em 1rem;
            display: flex;
            justify-content: center;
            margin: 5px 5px 0;
            li {
                width: 50%;
                max-width: 385px;
                &:first-child {
                    padding-right: .75rem;
                }
                &:last-child {
                    padding-left: .75rem;
                }
                .text-sub {
                    font-size: .75em;
                    font-weight: bold;
                }
                .btn-dexpo {
                    margin-top: .25rem;
                    line-height: 1.2;
                    padding: 10px;
                    min-width: 13rem;
                    width: 100%;
                    text-decoration: none;
                    font-size: 0.875rem;
                    height: 50px;
                }
                .text-for-pc {
                    display: block;
                }
                .text-for-sp {
                    display: none;
                }
            }
        }
        .intro-description {
            // background-color: $white; RT
            padding: 0 11rem;
            margin: 0 5px 5px;
            text-align: justify;
            font-size: 0.75rem;
            word-break: break-all; // fix on IE
            word-break: break-word;
        }
    }

    .set-fc-head-button{
        position: absolute;
        right: 1rem;
        top: 2rem;
        z-index: 1000;
        background-color: #fff;
        border-radius: 0.2rem;
        .navbar-toggler {
            padding: 0.4rem 0.75rem;
            .navbar-toggler-icon {
                background: transparent;
                position: relative;
                display: inline-block;
                cursor: pointer;
                width: 1.8em;
                height: 1.2em;
                > span {
                    position: absolute;
                    height: 3px;
                    background: #333333;
                    width: 100%;
                    top: 4%;
                    left: 0;
                    transition: 0.5s;
                    &:nth-child(2) {
                        top: 42%;
                    }
                    &:nth-child(3) {
                        top: 80%;
                        bottom: 0;
                    }
                }
            }
        }
    }

    .set-fc-head-auth-menu{
        position: absolute;
        right: 14rem;
        top: 2rem;
        z-index: 1;
        background-color: #fff;
        border-radius: 0.2rem;
        padding: 0.5rem 0.6rem;
        a{
            font-size: 16px;
            font-weight: 600;
            color: #727272;
            padding: 0 0.5rem;
            &:first-child{
                border-right: 1px solid #727272;
            }
            &:hover{
                color: #CECECE;
                text-decoration: none;
            }
        }
    }
}

.section-separate {
    // height: 12rem;
}

// Section news list
.section-events {
    background-color: #333333;
    color: $white;
    padding-bottom: 10rem;
    overflow-x: hidden;
    &-block{
        margin-top: 10rem;
        border-left: 4px solid $key-color;
    }
    .section-title {
        color: $dark-key-color;
        // margin: 6rem auto 3rem auto;
        // padding-top: 5rem;
        &::before {
            color: $key-color;
            opacity: 0.1;
            top: 75%;
        }
    }
    .section-desc {
        //max-width: 730px;
        margin: 0 auto;
        >p {
            font-size: 1.125rem;
            color: $black;
            &:first-child {
                margin-top: 1rem;
                font-weight: bold;
                font-size: 2.5rem;
                line-height: 1.3;
            }
            &:nth-child(2) {
                margin-top: 1.5rem;
                span {
                    display: inline-block;
                }
            }
        }
    }
    .item-news {
        border: 0;
        border-radius: 0;
        height: 100%;
        .thumbnail-news {
            padding: 7px;
            height: 300px;
            overflow: hidden;
            display: flex;
            
            img {
                border-radius: 20px;
                display: inline-flex;
                margin: 0 auto;
                -o-object-fit: cover;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .card-body {
            padding: 0;
            top: -60px;
            position: relative;
        }
        .cate-news {
            color: $key-color;
            font-size: 0.8rem;
            padding: 4px;
            // min-width: 150px;
            text-align: center;
            border-bottom: 4px solid;
            display: inline-block;
            line-height: 1;
            letter-spacing: 2px;
            margin-top: 1rem;
            strong {
                font-size: 1.1rem;
            }
        }
        .title-news {
            padding: 1.3rem 1.7rem;
            color: $black;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.3;
            text-decoration: none;
        }
        .desc-news {
            color: $black;
            padding: 0 1.7rem 1.3rem 1.7rem;
            p {
                margin-bottom: .25rem;
                color: $gray;
            }
        }
    }
}

// Section seminar
.section-seminar {
    background-color: $white;
    color: $black;
    padding-bottom: 6rem;
    overflow-x: hidden;
    .section-title {
        margin-top: 7rem;
        &:before {
            color: $dark-key-color;
            // opacity: 0.1;
        }
    }
    .other-text {
        font-weight: bold;
        text-align: center;
        font-size: 1.5rem;
        margin-top: 3rem;
    }
    .other-button {
        margin-top: 2rem;
        background-color: $pink;
        color: $white;
        font-weight: bold;
        padding: .3rem 2rem;
        text-decoration: none;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        &:hover {
            color: $white;
        }
    }
    &-set-fc-bg:nth-child(odd){
        background-color: #EDEDED;
    }
    .s-profile-comp-text {
        height: 125px;
        overflow: hidden;
    }
    .btn-read-more{
        color: #333333;
        background: linear-gradient(to bottom, rgba(255, 0, 0, 0), white);
        cursor: pointer;
        position: relative;
        top: -60px;
        right: 10px;
        padding-top: 55px;
        text-align: right;
        width: 100%;
        text-decoration: underline;
        &:after {
            content: "";
            border: 1px solid #333333;
            border-bottom-color: transparent;
            border-left-color: transparent;
            height: 8px;
            width: 8px;
            bottom: 10px;
            right: -15px;
            position: absolute;
            transform: rotate(135deg);
        }
    }
    &-set-fc-bg{
        &:nth-child(odd) {
            .btn-read-more{
                background: linear-gradient(to bottom, rgba(255,0,0,0), rgba(237,237,237,1));
            }
        }
    }
}

// Section gallery
.section-gallery {
    padding-bottom: 2rem;
    .section-title {
        font-size: 1.875rem;
        margin: 2rem auto;
    }
    .logo-item {
        margin-bottom: 1.5rem;
    }
    .client-logo {
        // border: 1px solid #cccccc;
        max-width: 320px;
        max-height: 200px;
        min-height: 200px;
        overflow: hidden;
        height: 100%;
        a {
            display: flex;
            height: 100%;
            width: 100%;
            text-decoration: none;
            align-items: center;
            justify-content: center;
            // background-color: $white;
        }
        img {
            display: inline-flex;
            max-width: 100%;
            max-height: 21rem;
            -o-object-fit: contain;
            object-fit: contain;
            height: 100%;
        }
    }
}

// Section exhibitor
.section-exhibitor {
    // background-color: $white;
    background-color: #EDEDED;
}

// Section sponsor
.section-sponsor {
    .section-title{
        color: $white;
        &.set-rt-vrunder::before{
            border: 1px solid $white;
        }
    }
    background-color: $key-color;
    .sponsor-header {
        margin: 0 auto 0.1rem auto;
        padding: .5rem;
        background-color:$key-color;
        color: $white;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        line-height: 2;
    }
    .client-logo {
        max-width: 100%;
        max-height: 100%;
    }

    &-fc-set-body{
        background-color:$key-color;
        padding-bottom: 50px;
    }
}

// Section loto
.section-loto {
    min-height: 25rem;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

// Section sns
.section-sns{
    .setrt-h{
        height: 400px;
        display: block;
        overflow-y: scroll;
    }
    .section-sub-title{
        color: #305767;
        font-size: 32px;
        font-weight: medium;
    }
    .set-fc-setrt-h{
        :hover{
            transform: scale(1.05);
            transition: .5s ease-in-out;
        }
    }
}

// Section ovice
.section-ovice {
    background-color: $white;
    .container-ovice {
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
        padding: 3.5rem;
    }
    img {
        max-width: 100%;
    }
}

// Section notify
.section-notify {
    background-color: $white;

    .notify-item {
        width: 100%;
        text-align: center;
    }

    img {
        max-width: 100%;
    }
}

// Section co-organizer
.section-co-organizer {
    background-color: $key-color;
    .container-co-organizer {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding: 3.5rem;
    }
    img {
        max-width: 100%;
    }
}

// Section sponsor-static
.section-sponsor-static {
    background-color: $white;
    .container-sponsor-static {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding: 3.5rem;
    }
    img {
        max-width: 100%;
    }
}

// Section news
.section-news {
    padding: 10px;
    font-size: 12px;
    .container {
        // margin-left: 5%;
        // max-width: 100%;
        .btn-list-news {
            border: 1px solid #fff;
            padding: 5px;
        }

        .news-title {
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }


    .news-content {
        display: block;
        height: 30px;
        width: 100%;
        overflow: hidden;
    }
    
    .news-content-body {
        display: inline-block;
        color: #fff;
        line-height: 30px;
        white-space: nowrap;
        animation-name: animate-banner;
        animation-iteration-count: infinite;
        animation-duration:60s;
        animation-timing-function:linear;
    }
    
    .news-content-body > * {
        display: inline-block;
    }
    
    @keyframes animate-banner {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-300%);
        }
    }
}

// Section online
.section-online {
    background-color: #E7F3F7;
    padding-bottom: 8rem;
    .section-title {
        margin: 7rem auto 1.5rem auto;
        &:before {
            color: $key-color;
            // opacity: .1;
        }
    }
    .section-desc {
        text-align: center;
        font-weight: bold;
        line-height: 1.8;
        font-size: 1.125rem;
    }
    .online-item {
        // border: 1px solid $key-color;
        padding: 1.5rem;
        background-color: $white;
        height: 100%;
        .ol-title {
            color: $key-color;
            font-weight: bold;
            text-decoration: none;
            text-align: center;
            font-size: 1.5rem;
            margin-bottom: 40px;
        }
        .ol-desc {
            margin-top: 1rem;
            line-height: 1.8;
            .ol-text-small {
                font-size: 0.75rem;
            }
        }
    }

    .btn-operate {
        border: 1px solid;
        border-radius: 5px;
        padding: 1.2rem;
        min-width: 23rem;
        color: #333333;
        text-decoration: none;
        position: relative;
        display: inline-block;
        font-size: 0.8rem;
        margin-top: 1.5rem;
        &:after {
            content: "";
            border: 1px solid #333333;
            border-bottom-color: transparent;
            border-left-color: transparent;
            height: 8px;
            width: 8px;
            top: 50%;
            right: 1rem;
            margin-top: -4px;
            position: absolute;
            transform: rotate(45deg);
        }
    }
}

.section-faq{
    .section-title {
        &.set-rt-vrunder{
            &::before {
                border: 1px solid #fff;
            }
        }
    }
}
// Section inquiry
.section-inquiry {
    padding-bottom: 3rem;
    background-color: $key-color;
    color: $white;
    .section-title {
        color: $white;
        margin: 3rem auto 1.8rem auto;
        &:before {
            color: rgba(255, 255, 255, 0.1);
        }
    }
    .section-desc {
        text-align: center;
    }
    .btn-inquiry {
        border: 1px solid;
        border-radius: 5px;
        padding: 0.5rem 1.2rem 0.5rem;
        min-width: 23rem;
        color: #ffffff;
        text-decoration: none;
        position: relative;
        display: inline-block;
        font-size: 0.8rem;
        margin-top: 1.5rem;
        &:hover {
            color: $white;
        }
        &:after {
            content: '';
            border: 1px solid;
            border-bottom-color: transparent;
            border-left-color: transparent;
            height: 8px;
            width: 8px;
            top: 50%;
            right: 1rem;
            margin-top: -4px;
            position: absolute;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

//Section contact
.section-contact {
    background-color: $white;
    color: $black;
    .section-title {
        color: $key-color-dark-title;
        &:before {
            color: $key-color;
            // opacity: 0.1;
        }
    }
    .btn-inquiry {
        color: $black;
        &:hover {
            color: $black;
        }
    }
}

// Footer style
footer {
    background-color: $key-color;
    color: $white;
    align-items: center;
    font-size: .9rem;
    padding: .8rem 0;
}

@media screen and (max-width: 1024px) {
    .set-fc-head-auth-menu{
        position: absolute;
        left: 0;
        top: 15.3rem !important;
        z-index: 1;
        background-color: #fff;
        border-radius: 0.2rem;
        padding: 0.5rem 0.6rem;
        width: 100%;
        a{
            font-size: 16px;
            font-weight: 600;
            color: #727272;
            padding: 0 0.5rem;
            &:first-child{
                border-right: 1px solid #727272;
            }
            &:hover{
                color: #CECECE;
                text-decoration: none;
            }
        }
    }

    .top-lang {
        position: absolute;
        right: 0.5rem;
        top: 15.3rem;
        min-width: 10rem;
        z-index: 1;
        background-color: #fff;
        border-radius: 0.2rem;
    }
    .set-solo-lang-custom{
        .top-lang {
            position: relative;
            top: 1rem !important;
            right: 1rem !important;
            width: 150px;
            right: 0;
            margin-left: auto;

        }
    }

    .carousel .carousel-item > div {
        max-height: 240px;
    }

    .top-header {
        .top-intro {
            .intro {
                .intro-description {
                    padding: 0 4rem;
                }
            }
        }
        .top-slogan {
            &-setrt{
                max-height: unset;
                .setrt-head-logo{
                    &-text{
                        font-size: 16px;
                    }
                    &-date{
                        h4{
                            font-size: 15px;
                        }
                        &-time{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    // Style of header
    .top-header {
        .top-intro {
            .intro {
                .term-held {
                    span {
                        font-size: 1.2rem;
                    }
                }
                .intro-description {
                    padding: 0 2rem;
                }
            }
        }
        .top-slogan {
            .slogan-text {
                line-height: 1.1;
                font-size: 1.7rem;
                &:first-child {
                    margin-top: -3rem;
                }
            }
        }
    }
    .section-title {
        &::before {
            font-size: 4rem;
        }
    }
    // Section event
    .section-events {
        padding-bottom: 4rem;
        .section-desc {
            max-width: 92%;
            >p {
                &:first-child {
                    font-size: 1.375rem;
                    line-height: 1.6;
                }
            }
        }

        .item-news {
            margin-bottom: -60px;
        }
    }
    // Section seminar
    .section-seminar {
        padding-bottom: 4rem;
        .section-title {
            margin: 2rem auto;
        }
        .other-text {
            margin-top: 1rem;
        }
       
    }
    // Section online
    .section-online {
        .section-title {
            margin-top: 2rem;
        }
    }
    // Section inquiry
    .section-inquiry {
        .section-title {
            margin-top: 2rem;
        }
    }

    // Section sns
    .section-sns{
        .section-sub-title{
            font-size:20px;
            font-weight: medium;
        }
    }

    // Footer style
    footer {
        div[class^="col-"] {
            padding: 0;
        }
    }
    .top-lang-custom {
        .top-lang {
            top: 3rem;
        }
    }
    .top-lang {
        top: 51vh;
    }
    .set-fc-head-auth-menu{
        top: 51vh !important;
    }
}

@media screen and (max-width: 639px) {
    // Style of header
    .top-header {
        .top-slogan {
            min-height: 25rem;
        }
        .top-intro {
            .intro {
                max-width: 100%;
            }
            .intro-button {
                li {
                    margin-left: 0.25rem;
                    margin-right: 0.25rem;
                }
            }
        }
    }
    .section-title {
        &::before {
            font-size: 3rem;
            white-space: normal;
        }
    }
    // Section seminar
    .section-seminar {
        padding-bottom: 4rem;

        .block-seminar {
            margin-bottom: 0;

            .s-profile-comp-text {
                height: auto;
            }
        }
    }
    .section-gallery {
        .col-sm-6,
        .col-md-3 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }

        .client-logo {
            min-height: auto;
        }
    }
    .section-sponsor {
        .gold-sponsors {
            .col-sm-6,
            .col-md-3 {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .section-sponsor-fc-set-body {
            padding-bottom: 20px;
        }
    }
}

@media screen and (max-width: 576px) {
    .top-header {
        .top-intro {
            .intro-button {
                li {
                    &:first-child {
                        padding-right: 0;
                    }
                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    // Section ovice
    .section-ovice, .section-notify {
        .container-ovice {
            padding: 0;
        }
        img {
            width: 100%;
        }
        .sm-mobile {
            display: block;
        }
        .pc-desktop {
            display: none;
        }
    }
    // Section co-organizer
    .section-co-organizer {
        .container-co-organizer {
            padding: 3rem 0;
        }
        img {
            width: 100%;
        }
        .sm-mobile {
            display: block;
        }
        .pc-desktop {
            display: none;
        }
    }
    // Section co-organizer
    .section-sponsor-static {
        .container-sponsor-static {
            padding: 3rem 0;
        }
        img {
            width: 100%;
        }
        .sm-mobile {
            display: block;
        }
        .pc-desktop {
            display: none;
        }
    }
    // Section online
    .section-online {
        .section-desc {
            text-align: left;
        }
        .col-sm-6,
        .col-md-4 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: .7rem !important;
            &:nth-child(odd) {
                padding-right: 5px;
            }
            &:nth-child(even) {
                padding-left: 5px;
            }
        }
        .online-item {
            padding: .5rem;
            .ol-desc {
                font-size: .9em;
            }
        }
    }
    .section-exhibitor {
        .client-logo {
            min-height: 100px;
        }
    }
    // Section seminar
    .section-seminar {
        padding-bottom: 4rem;

        .block-seminar {
            margin-bottom: 0;

            .s-profile-comp-text {
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .only-pc {
        display: none;
    }

    // Style of header
    .top-header {
        // padding-top: .625em;
        .top-slogan {
            display: flex;
            min-height: 15rem;
            max-height: 34rem;
            img {
                display: none;
                max-width: 100%;
                max-height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                -o-object-position: top;
                object-position: top;
                &.sm-mobile {
                    display: inline-flex;
                    // margin-top: -.5rem;
                    object-fit: cover;
                }
            }
            .slogan-text {
                font-size: 1.5rem;
                &:first-child {
                    margin-top: -8rem;
                }
            }
            &-setrt{
                max-height: unset;
                .setrt-head-logo{
                    &-text{
                        font-size: 16px;
                    }
                    &-date{
                        h4{
                            font-size: 15px;
                        }
                        &-time{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        .top-intro {
            max-height: 500000rem;
            .container,
            .col-md-12,
            .row {
                padding: 0 !important;
                margin: 0 !important;
            }
            .content-intro {
                max-width: 100%;
                min-height: 15.5rem;
                top: 0;
                &.no-date-release {
                    min-height: 0rem;
                }
                .box-intro {
                    position: inherit;
                }
            }
            .intro {
                max-width: 100%;
                img {
                    display: none;
                    &.sm-mobile {
                        display: block;
                        width: 100%;
                    }
                }
                .intro-end-line {
                    height: 1px;
                }
                .intro-description {
                    padding: 0 3rem;
                    margin: 0 10px 10px;
                }
            }
            .intro-button {
                display: block;
                padding: 1.5rem 2.1rem;
                text-align: center;
                margin: 10px 10px 0;
                li {
                    width: 100%;
                    max-width: 270px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: .5rem;
                    &:last-child {
                        margin-top: 1.5rem;
                    }
                    .btn-dexpo {
                        line-height: 1.2;
                        font-size: .75rem;
                    }
                    .text-for-pc {
                        display: none;
                    }
                    .text-for-sp {
                        display: block;
                    }
                }
            }
        }
    }
    .section-title {
        font-size: 1.5rem !important;
        padding: 1.8rem 0;
        overflow: initial;
        &::before {
            font-size: 4rem;
            line-height: .9;
        }
    }
    .section-desc {
        p {
            font-size: .875rem;
        }
    }
    .section-separate {
        // display: none;
    }
    // Section event
    .section-events {
        &-block{
            margin-top: 2rem;
        }
        .section-title {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
        }
        .section-desc {
            font-size: .875rem;
            >p {
                &:last-child {
                    font-size: 1em;
                }
            }
        }
        .item-news {
            margin-bottom: -60px;

            .thumbnail-news {
                height: 14.8rem;
            }
            .cate-news {
                text-align: left;
                padding-left: 1.25rem;
            }
            .title-news {
                font-size: 1.125rem;
                padding: 2.18rem 1.25rem .9rem;
            }
            .desc-news {
                padding-left: 1.1rem;
                padding-right: 1.1rem;
                font-size: .75rem;
            }
        }
    }
    // Section online
    .section-online {
        padding-bottom: 4rem;
        .section-title {
            padding: 3rem 0;
        }
        .section-desc {
            font-size: 0.938rem;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;
            line-height: 2;
        }
        .online-item {
            .ol-title {
                font-size: 1.125rem;
                margin-bottom: auto;
                max-width: 75%;
                min-height: 5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .btn-operate {
            min-width: 17rem;
            line-height: 1.5;
        }
    }
    .section-inquiry {
        .section-title {
            margin-top: 1.5rem;
            margin-bottom: 0;
        }
        .section-desc {
            font-size: .75rem;
            max-width: 235px;
            margin-left: auto;
            margin-right: auto;
        }
        .btn-inquiry {
            min-width: 17rem;
            line-height: 1.5;
        }
    }
    // Footer style
    footer {
        padding-bottom: 1.5rem;
    }
}

@media screen and (max-width: 320px) {
    .section-online {
        .btn-operate {
            min-width: 12rem;
        }
    }
    .section-inquiry {
        .btn-inquiry {
            min-width: 12rem;
        }
    }
}